<template>
  <div class="h-full">
    <loader :loading="loading" :backdrop="true" />
    <div class="form-row w-full">
      <FinalForm
        ref="form"
        @submit="saveData"
        :initialValues="initialValues"
        class="w-full pr-2"
      >
        <div class="stick bg-white pb-3 z-10">
          <div class="form-row">
            <label class="font-semibold" style="color: var(--highlightColor500)"
              >additional provisions
            </label>
          </div>
        </div>
        <div>
          <label class="form-section-sub-title"
            >ADDITIONAL SPECIAL PROVISIONS</label
          >
          <div class="form-row">
            <text-input
              rows="5"
              id="paintDesc"
              v-model="paintDesc"
              multiline
            />
          </div>
          <div class="h-4"></div>

          <label class="form-section-sub-title"
            >PER-PERSON RENTAL OF DWELLING</label
          >
          <div class="form-row">
            <div class="text-title">we</div>
            <CheckboxInputSmall
              type="checkbox"
              name="mayAssignPersonToShareBedroom"
            />
            <div class="text-title">may</div>
            <CheckboxInputSmall
              type="checkbox"
              name="mayNotAssignPersonToShareBedroom"
            />
            <div class="text-title">
              may not assign another person to share bedroom
            </div>
          </div>
          <div class="form-row">
            <div class="text-title">
              Max. other occupants sharing common living areas
            </div>
            <TextInputSmall name="maxLivingAreas" type="number" />
          </div>
          <div class="form-row">
            <label class="text-title">Transfer Fee </label>
            <TextInputSmall name="transferFee" type="number" />
          </div>
          <div class="h-4"></div>

          <label class="form-section-sub-title"
            >CONSTRUCTION ACTIVITIES ADDENDUM</label
          >
          <div class="form-row">
            <text-input
              rows="5"
              id="constructionActivities"
              v-model="constructionActivities"
              multiline
            />
          </div>
        </div>
      </FinalForm>
      <modal-footer :footer="footer" :primary="saveData"></modal-footer>
    </div>
  </div>
</template>

<script>
import ModalNavigation from "@/mixins/ModalNavigation";
import Loader from "@/components/ui/Loader";
import TextInputSmall from "@/components/form/TextInputSmall";
import { FinalForm } from "vue-final-form";
import InitializeFormMixin from "@/components/form/InitializeFormMixin";
import ModalFooter from "@/components/ui/modals/ModalFooter";
import CheckboxInputSmall from "@/components/form/CheckboxInputSmall";
import TextInput from '@/components/ui/TextInput';

export default {
  mixins: [ModalNavigation, InitializeFormMixin],
  components: {
    Loader,
    FinalForm,
    TextInputSmall,
    ModalFooter,
    CheckboxInputSmall,
    TextInput,
  },
  data() {
    return {
      paintDesc: "",
      mayAssignPersonToShareBedroom: "",
      mayNotAssignPersonToShareBedroom: "",
      maxLivingAreas: "",
      transferFee: "",
      constructionActivities: "",
      footer: {
        primaryButton: "save",
      },
      loading: false,
    };
  },
  computed: {
    initialValues() {
      return {
        paintDesc: "",
        mayAssignPersonToShareBedroom: "",
        mayNotAssignPersonToShareBedroom: "",
        maxLivingAreas: "",
        transferFee: "",
        constructionActivities: "",
      };
    },
  },
  methods: {
    saveData() {
      this.$refs.form.formState.values.paintDesc = this.paintDesc;
      this.$refs.form.formState.values.constructionActivities =
        this.constructionActivities;
    },
  },
};
</script>

<style scoped>
input {
  accent-color: var(--highlightColor500) !important;
}
.stick {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
button {
  outline: none;
  box-shadow: none;
}
</style>
