<template>
  <div class="h-full">
    <loader :loading="loading" :backdrop="true" />
    <div class="form-row w-full">
      <FinalForm
        :submit="saveData"
        :initialValues="initialValues"
        class="w-full pr-2"
        ref="form"
      >
        <div class="stick bg-white pb-3 z-10">
          <div class="form-row">
            <label class="font-semibold" style="color: var(--highlightColor500)"
              >electric, water/wastewater</label
            >
          </div>
        </div>
        <div>
          <label class="form-section-sub-title"
            >electric submetering/allocation and billing</label
          >
          <div class="form-row">
            <div class="text-title">reconnection fee</div>
            <TextInputSmall type="number" name="reconnectionFee" />
            <div class="text-title">(not to exceed $10)</div>
          </div>
          <div class="h-4"></div>

          <label class="form-section-sub-title"
            >submetering addendum only</label
          >
          <div class="form-row">
            <div class="text-title">pay your electric bill to</div>
          </div>
          <div class="form-row">
            <button>
              <CheckboxInputSmall
                type="checkbox"
                name="payBillToOurRespective"
              />
            </button>
            <div class="text-title">our representative</div>
            <button>
              <CheckboxInputSmall type="checkbox" name="payBillToAddress" />
            </button>
            <div class="text-title">the address on the bill</div>
          </div>
          <div class="h-4"></div>

          <label class="form-section-sub-title"
            >allocation and billing addendum only</label
          >
          <div class="form-row">
            <div class="text-title">
              a percentage reflecting apartment unit's share of the total square
              footage in the apartment community. that percent is
            </div>
            <TextInputSmall type="number" name="unitSQFT" />
          </div>
          <div class="form-row">
            <div class="text-title">
              before the bill is allocated, a deduction of
            </div>
            <TextInputSmall type="number" name="electricityInCommonAreaa" />
            <div class="text-title">
              will be made to cover estimated electricity consumption in any
              common areas
            </div>
          </div>
          <div class="form-row">
            <div class="text-title">
              the average monthly bill for tenant's apartment for the previous
              calendar year was
            </div>
            <TextInputSmall type="number" name="monthlyBillForTenant" />
            <div class="text-title">
              using the allocation formula checked above
            </div>
          </div>
          <div class="h-4"></div>

          <label class="form-section-sub-title"
            >water/wastewater submetering/cost allocation</label
          >
          <div class="form-row">
            <div class="text-title">
              average monthly bill in previous calender year
            </div>
            <TextInputSmall name="monthlyBill" type="number" />
          </div>
          <div class="form-row">
            <div class="text-title">varying from</div>
            <TextInputSmall name="monthlyBillLow" type="number" />
            <div class="text-title">to</div>
            <TextInputSmall name="monthlyBillHigh" type="number" />
            <div class="text-title">
              for the lowest to highest monthly bills
            </div>
          </div>
          <div class="h-4"></div>

          <label class="form-section-sub-title"
            >submetering addendum only</label
          >
          <div class="form-row">
            <div class="text-title">a service fee of</div>
            <TextInputSmall type="number" name="wastewaterServiceFee" />
            <div class="text-title">
              will be added to your monthly water/wastewater service charges
            </div>
          </div>
          <div class="form-row">
            <div class="text-title">submetered water consumption from the</div>
          </div>
          <div class="form-row">
            <TextInputSmall
              type="number"
              name="submeteredWaterFirstDayOfMonth"
            />
            <div class="text-title">day of the month to the</div>
            <TextInputSmall
              type="number"
              name="submeteredWaterLastDayOfMonth"
            />
            <div class="text-title">day of the month</div>
          </div>
          <div class="h-4"></div>

          <label class="form-section-sub-title"
            >cost allocation addendum only</label
          >
          <div class="form-row">
            <div class="text-title">
              utility company sends monthly bill to us about the
            </div>
            <TextInputSmall name="utilityMonthlyCost" type="number" />
            <div class="text-title">day of the month</div>
          </div>
          <div class="form-row">
            <div class="text-title">section 24.124 of the PUC rules</div>
          </div>
          <div class="form-row">
            <button>
              <CheckboxInputSmall type="checkbox" name="subdivision1" />
            </button>
            <div class="text-title">subdivision (i) actual occupancy;</div>
          </div>
          <div class="form-row">
            <button>
              <CheckboxInputSmall type="checkbox" name="subdivision2" />
            </button>
            <div class="text-title">
              subdivision (ii) ratio occupancy (PUC average for number of
              occupants in unit);
            </div>
          </div>
          <div class="form-row">
            <button>
              <CheckboxInputSmall type="checkbox" name="subdivision3" />
            </button>
            <div class="text-title">
              subdivision (iii) average occupancy (PUC avg. for no. of bedrooms
              in unit);
            </div>
          </div>
          <div class="form-row">
            <button>
              <CheckboxInputSmall type="checkbox" name="subdivision4" />
            </button>
            <div class="text-title">
              subdivision (iv) combination of occupancy and square feet of the
              apartment; or
            </div>
          </div>
          <div class="form-row">
            <button>
              <CheckboxInputSmall type="checkbox" name="subdivision5" />
            </button>
            <div class="text-title">
              subdivision (v) submetered hot/cold water, ratio to total.
            </div>
          </div>
        </div>
      </FinalForm>
      <modal-footer :footer="footer" :primary="saveData"></modal-footer>
    </div>
  </div>
</template>

<script>
import ModalNavigation from "@/mixins/ModalNavigation";
import Loader from "@/components/ui/Loader";
import ModalFooter from "@/components/ui/modals/ModalFooter";
import InitializeFormMixin from "@/components/form/InitializeFormMixin";
import { FinalForm } from "vue-final-form";
import TextInputSmall from "@/components/form/TextInputSmall";
import CheckboxInputSmall from "@/components/form/CheckboxInputSmall";

export default {
  name: "Electric Water/Wastewater",
  components: {
    Loader,
    FinalForm,
    ModalFooter,
    TextInputSmall,
    CheckboxInputSmall,
  },
  mixins: [ModalNavigation, InitializeFormMixin],
  computed: {
    initialValues() {
      return {
        reconnectionFee: "",
        payBillToOurRespective: "",
        payBillToAddress: "",
        unitSQFT: "",
        electricityInCommonAreaa: "",
        monthlyBillForTenant: "",
        monthlyBill: "",
        monthlyBillLow: "",
        monthlyBillHigh: "",
        wastewaterServiceFee: "",
        submeteredWaterFirstDayOfMonth: "",
        submeteredWaterLastDayOfMonth: "",
        utilityMonthlyCost: "",
        subdivision1: "",
        subdivision2: "",
        subdivision3: "",
        subdivision4: "",
        subdivision5: "",
      };
    },
  },
  data() {
    return {
      loading: false,
      footer: {
        primaryButton: "save",
      },
      reconnectionFee: "",
      payBillToOurRespective: "",
      payBillToAddress: "",
      unitSQFT: "",
      electricityInCommonAreaa: "",
      monthlyBillForTenant: "",
      monthlyBill: "",
      monthlyBillLow: "",
      monthlyBillHigh: "",
      wastewaterServiceFee: "",
      submeteredWaterFirstDayOfMonth: "",
      submeteredWaterLastDayOfMonth: "",
      utilityMonthlyCost: "",
      subdivision1: "",
      subdivision2: "",
      subdivision3: "",
      subdivision4: "",
      subdivision5: "",
    };
  },
  methods: {
    saveData() {},
  },
};
</script>

<style scoped>
input {
  accent-color: var(--highlightColor500) !important;
}

.stick {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
button {
  outline: none;
  box-shadow: none;
}
</style>