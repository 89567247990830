<template>
  <div class="h-full">
    <loader :loading="loading" :backdrop="true" />
    <div class="form-row w-full">
      <FinalForm
        ref="form"
        @submit="saveData"
        :initialValues="initialValues"
        class="w-full pr-2"
      >
        <div class="stick bg-white pb-3 z-10">
          <div class="form-row">
            <label class="font-semibold" style="color: var(--highlightColor500)"
              >early lease
            </label>
          </div>
        </div>
        <div>
          <label class="form-section-sub-title"
            >Complete This Section ONLY IF Your Company/Property Uses The Lease
            Addendum For Early Lease Termination Of Lease Contract
          </label>
          <div class="form-row">
            <div class="text-title">
              you may terminate the lease giving us at least
            </div>
            <TextInputSmall name="noticeTermination" type="number" />
            <div class="text-title">days written notice of termination</div>
          </div>

          <div class="form-row">
            <div class="text-title">the termination date</div>
            <button>
              <CheckboxInputSmall type="checkbox" name="terminationByDay" />
            </button>

            <div class="text-title">must be the last day of the month, or</div>

            <button>
              <CheckboxInputSmall type="checkbox" name="terminationByMonth" />
            </button>

            <div class="text-title">may be during a month</div>
          </div>

          <div class="form-row">
            <div class="text-title">you must pay us a termination fee of</div>
            <TextInputSmall name="terminationFee" type="number" />
          </div>

          <div class="form-row">
            <div class="text-title">
              The termination fee is due and payable no later than
            </div>
            <TextInputSmall name="terminationDueDays" type="number" />
            <div class="text-title">
              days after you give us your termination notice
            </div>
          </div>
          <div class="form-row">
            <div class="text-title">our right of early termination</div>
            <button>
              <CheckboxInputSmall type="checkbox" name="earlyTermination" />
            </button>

            <div class="text-title">is, or</div>
            <button>
              <CheckboxInputSmall
                type="checkbox"
                name="earlyTerminationSituation"
              />
            </button>

            <div class="text-title">
              is not limited to a particular fact situation
            </div>
          </div>
          <div class="h-4"></div>

          <label class="form-section-sub-title no-padding-y"
            >SPECIAL PROVISION FACTS</label
          >
          <div class="h-4"></div>
          <div class="form-row">
            <text-input
              id="specialProvision"
              v-model="specialProvision"
              rows="5"
              multiline
            />
          </div>
        </div>
      </FinalForm>
      <modal-footer :footer="footer" :primary="saveData"></modal-footer>
    </div>
  </div>
</template>

<script>
import ModalNavigation from "@/mixins/ModalNavigation";
import Loader from "@/components/ui/Loader";
import CheckboxInputSmall from "@/components/form/CheckboxInputSmall";
import TextInputSmall from "@/components/form/TextInputSmall";
import { FinalForm } from "vue-final-form";
import InitializeFormMixin from "@/components/form/InitializeFormMixin";
import ModalFooter from "@/components/ui/modals/ModalFooter";
import NotifyMixin from "@/mixins/NotifyMixin";
import TextInput from '@/components/ui/TextInput';

export default {
  mixins: [ModalNavigation, InitializeFormMixin, NotifyMixin],
  components: {
    Loader,
    FinalForm,
    CheckboxInputSmall,
    TextInputSmall,
    ModalFooter,
    TextInput,
  },
  data() {
    return {
      noticeTermination: "",
      terminationByDay: "",
      terminationByMonth: "",
      terminationFee: "",
      terminationDueDays: "",
      earlyTermination: "",
      earlyTerminationSituation: "",
      specialProvision: "",
      footer: {
        primaryButton: "save",
      },
    };
  },
  computed: {
    initialValues() {
      return {
        noticeTermination: "",
        terminationByDay: "",
        terminationByMonth: "",
        terminationFee: "",
        terminationDueDays: "",
        earlyTermination: "",
        earlyTerminationSituation: "",
        specialProvision: "",
      };
    },
  },

  methods: {
    saveData() {
      this.$refs.form.formState.values.specialProvision = this.specialProvision;

      if (
        !this.$refs.form.formState.values.terminationByMonth &&
        !this.$refs.form.formState.values.terminationByDay
      ) {
        this.notifyError(
          "Please select one checkbox from the termination section."
        );
      } else if (
        !this.$refs.form.formState.values.earlyTerminationSituation &&
        !this.$refs.form.formState.values.earlyTermination
      ) {
        this.notifyError(
          "Please select one checkbox from the early termination section."
        );
      }
    },
  },
};
</script>

<style scoped>
input:checked {
  accent-color: var(--highlightColor500) !important;
}

.stick {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
button {
  outline: none;
  box-shadow: none;
}
</style>

