<template>
  <div class="h-full flex flex-col">
    <loader :loading="loading" :backdrop="true" />
    <div class="flex flex-1 overflow-auto">
      <div class="w-1/3 form-col sidenav overflow-auto">
        <div class="overflow-auto">
          <label
            class="form-section-title"
            style="margin-bottom: 2px; font-size: 1rem"
            >lease name</label
          >
          <div class="col">
            <div class="text-xl tracking-wider font-medium text-black mb-8">
              sample lease 1
            </div>
            <div class="h-4"></div>
            <div class="overflow-auto h-1/2">
              <div class="label">
                <label
                  class="text-title font-black"
                  style="font-size: 1.25rem; font-weight: 800"
                  >standard forms</label
                >
                <button
                  @click="toggleStandardForms"
                  v-if="this.canSeeStandardFroms"
                >
                  <icon name="minus" class="h-6 w-6 mt-2 text-black" />
                </button>
                <button
                  @click="toggleStandardForms"
                  v-if="!this.canSeeStandardFroms"
                >
                  <icon name="plus" class="h-6 w-6 mt-2 text-black" />
                </button>
              </div>

              <div class="lease-options mb-4" v-if="this.canSeeStandardFroms">
                <div
                  class="form-tab"
                  v-bind:class="{ active: isActive && currentTab == 1 }"
                >
                  <div class="form-icon">
                    <icon name="checkmark" class="h-3 w-4 mt-1 text-blue-500" />
                  </div>
                  <div class="form-name">
                    <div
                      class="form-row cursor-pointer"
                      style="font-size: 1rem"
                      @click="selectTab(1)"
                    >
                      lease terms
                    </div>
                  </div>
                </div>
                <div
                  class="form-tab"
                  v-bind:class="{ active: isActive && currentTab == 2 }"
                >
                  <div class="form-icon">
                    <input
                      name="checkmark"
                      type="checkbox"
                      class="w-4 h-4 mt-1 text-blue-500"
                      checked="checked"
                    />
                  </div>
                  <div class="form-name">
                    <div
                      class="form-row cursor-pointer"
                      style="font-size: 1rem"
                      @click="selectTab(2)"
                    >
                      student lease
                    </div>
                  </div>
                </div>
                <div
                  class="form-tab"
                  v-bind:class="{ active: isActive && currentTab == 3 }"
                >
                  <div class="form-icon">
                    <icon name="checkmark" class="h-3 w-4 mt-1 text-blue-500" />
                  </div>
                  <div class="form-name">
                    <div
                      class="form-row cursor-pointer"
                      style="font-size: 1rem"
                      @click="selectTab(3)"
                    >
                      other charges
                    </div>
                  </div>
                </div>
                <div
                  class="form-tab"
                  v-bind:class="{ active: isActive && currentTab == 4 }"
                >
                  <div class="form-icon">
                    <input
                      type="checkbox"
                      class="h-4 w-4 mt-1 text-blue-500"
                      style="background: black"
                    />
                  </div>
                  <div class="form-name">
                    <div
                      class="form-row cursor-pointer"
                      style="font-size: 1rem"
                      @click="selectTab(4)"
                    >
                      special provisions
                    </div>
                  </div>
                </div>
                <div
                  class="form-tab"
                  v-bind:class="{ active: isActive && currentTab == 5 }"
                >
                  <div class="form-icon">
                    <input
                      type="checkbox"
                      class="h-4 w-4 mt-1 text-blue-500"
                      checked="checked"
                    />
                  </div>
                  <div class="form-name">
                    <div
                      class="form-row cursor-pointer"
                      style="font-size: 1rem"
                      @click="selectTab(5)"
                    >
                      attachments
                    </div>
                  </div>
                </div>
                <div
                  class="form-tab"
                  v-bind:class="{ active: isActive && currentTab == 13 }"
                >
                  <div class="form-icon">
                    <input type="checkbox" class="h-4 w-4 mt-1 text-blue-500" />
                  </div>
                  <div class="form-name">
                    <div
                      class="form-row cursor-pointer"
                      style="font-size: 1rem"
                      @click="selectTab(13)"
                    >
                      animal addendum
                    </div>
                  </div>
                </div>
                <div
                  class="form-tab"
                  v-bind:class="{ active: isActive && currentTab == 16 }"
                >
                  <div class="form-icon">
                    <icon name="checkmark" class="h-3 w-4 mt-1 text-blue-500" />
                  </div>
                  <div class="form-name">
                    <div
                      class="form-row cursor-pointer"
                      style="font-size: 1rem"
                      @click="selectTab(16)"
                    >
                      lead hazard/misc.
                    </div>
                  </div>
                </div>
                <div
                  class="form-tab"
                  v-bind:class="{ active: isActive && currentTab == 15 }"
                >
                  <div class="form-icon">
                    <input type="checkbox" class="h-4 w-4 mt-1 text-blue-500" />
                  </div>
                  <div class="form-name">
                    <div
                      class="form-row cursor-pointer"
                      style="font-size: 1rem"
                      @click="selectTab(15)"
                    >
                      early lease
                    </div>
                  </div>
                </div>
                <div
                  class="form-tab"
                  v-bind:class="{ active: isActive && currentTab == 6 }"
                >
                  <div class="form-icon">
                    <icon name="checkmark" class="h-3 w-4 mt-1 text-blue-500" />
                  </div>
                  <div class="form-name">
                    <div
                      class="form-row cursor-pointer"
                      style="font-size: 1rem"
                      @click="selectTab(6)"
                    >
                      allocation addenda
                    </div>
                  </div>
                </div>
                <div
                  class="form-tab"
                  v-bind:class="{ active: isActive && currentTab == 7 }"
                >
                  <div class="form-icon">
                    <icon name="checkmark" class="h-3 w-4 mt-1 text-blue-500" />
                  </div>
                  <div class="form-name">
                    <div
                      class="form-row cursor-pointer"
                      style="font-size: 1rem"
                      @click="selectTab(7)"
                    >
                      misc. addenda
                    </div>
                  </div>
                </div>
                <div
                  class="form-tab"
                  v-bind:class="{ active: isActive && currentTab == 8 }"
                >
                  <div class="form-icon">
                    <input
                      type="checkbox"
                      class="h-4 w-4 mt-1 text-blue-500"
                      checked="checked"
                    />
                  </div>
                  <div class="form-name">
                    <div
                      class="form-row cursor-pointer"
                      style="font-size: 1rem"
                      @click="selectTab(8)"
                    >
                      remote/card/code
                    </div>
                  </div>
                </div>
                <div
                  class="form-tab"
                  v-bind:class="{ active: isActive && currentTab == 9 }"
                >
                  <div class="form-icon">
                    <input
                      type="checkbox"
                      class="h-4 w-4 mt-1 text-blue-500"
                      checked="checked"
                    />
                  </div>
                  <div class="form-name">
                    <div
                      class="form-row cursor-pointer"
                      style="font-size: 1rem"
                      @click="selectTab(9)"
                    >
                      electric, water/wastewater
                    </div>
                  </div>
                </div>
                <div
                  class="form-tab"
                  v-bind:class="{ active: isActive && currentTab == 10 }"
                >
                  <div class="form-icon">
                    <input type="checkbox" class="h-4 w-4 mt-1 text-blue-500" />
                  </div>
                  <div class="form-name">
                    <div
                      class="form-row cursor-pointer"
                      style="font-size: 1rem"
                      @click="selectTab(10)"
                    >
                      rent consessions
                    </div>
                  </div>
                </div>
                <div
                  class="form-tab"
                  v-bind:class="{ active: isActive && currentTab == 11 }"
                >
                  <div class="form-icon">
                    <icon name="checkmark" class="h-3 w-4 mt-1 text-blue-500" />
                  </div>
                  <div class="form-name">
                    <div
                      class="form-row cursor-pointer"
                      style="font-size: 1rem"
                      @click="selectTab(11)"
                    >
                      intrusion alarm
                    </div>
                  </div>
                </div>
                <div
                  class="form-tab"
                  v-bind:class="{ active: isActive && currentTab == 12 }"
                >
                  <div class="form-icon">
                    <icon name="checkmark" class="h-3 w-4 mt-1 text-blue-500" />
                  </div>
                  <div class="form-name">
                    <div
                      class="form-row cursor-pointer"
                      style="font-size: 1rem"
                      @click="selectTab(12)"
                    >
                      additional provision
                    </div>
                  </div>
                </div>
                <div
                  class="form-tab"
                  v-bind:class="{ active: isActive && currentTab == 14 }"
                >
                  <div class="form-icon">
                    <input type="checkbox" class="h-4 w-4 mt-1 text-blue-500" />
                  </div>
                  <div class="form-name">
                    <div
                      class="form-row cursor-pointer"
                      style="font-size: 1rem"
                      @click="selectTab(14)"
                    >
                      renters insurance
                    </div>
                  </div>
                </div>
                <div
                  class="form-tab"
                  v-bind:class="{ active: isActive && currentTab == 17 }"
                >
                  <div class="form-icon">
                    <icon name="checkmark" class="h-3 w-4 mt-1 text-blue-500" />
                  </div>
                  <div class="form-name">
                    <div
                      class="form-row cursor-pointer"
                      style="font-size: 1rem"
                      @click="selectTab(17)"
                    >
                      lease guaranty
                    </div>
                  </div>
                </div>
                <div
                  class="form-tab"
                  v-bind:class="{ active: isActive && currentTab == 18 }"
                >
                  <div class="form-icon">
                    <icon name="checkmark" class="h-3 w-4 mt-1 text-blue-500" />
                  </div>
                  <div class="form-name">
                    <div
                      class="form-row cursor-pointer"
                      style="font-size: 1rem"
                      @click="selectTab(18)"
                    >
                      summary information
                    </div>
                  </div>
                </div>
                <div
                  class="form-tab"
                  v-bind:class="{ active: isActive && currentTab == 19 }"
                >
                  <div class="form-icon">
                    <icon name="checkmark" class="h-3 w-4 mt-1 text-blue-500" />
                  </div>
                  <div class="form-name">
                    <div
                      class="form-row cursor-pointer"
                      style="font-size: 1rem"
                      @click="selectTab(19)"
                    >
                      covid-19 forms
                    </div>
                  </div>
                </div>
              </div>

              <div class="label">
                <label
                  class="text-title font-black"
                  style="font-size: 1.25rem; font-weight: 800"
                  >custom forms</label
                ><button
                  @click="toggleCustomForms"
                  v-if="this.canSeeCustomForms"
                >
                  <icon name="minus" class="h-6 w-6 mt-2 text-black" />
                </button>
                <button
                  @click="toggleCustomForms"
                  v-if="!this.canSeeCustomForms"
                >
                  <icon name="plus" class="h-6 w-6 mt-2 text-black" />
                </button>
              </div>

              <div
                class="lease-options mb-4"
                v-if="this.canSeeCustomForms"
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div class="overflow-auto w-2/3">
        <div>
          <LeaseTerms v-if="currentTab === 1" />
        </div>
        <div>
          <StudentLease v-if="currentTab === 2" />
        </div>
        <div>
          <OtherCharges v-if="currentTab === 3" />
        </div>
        <div>
          <SpecialProvisions v-if="currentTab === 4" />
        </div>
        <div>
          <Attachments v-if="currentTab === 5" />
        </div>
        <div>
          <AllocationAddenda v-if="currentTab === 6" />
        </div>
        <div>
          <MiscellaneousAddenda v-if="currentTab === 7" />
        </div>
        <div>
          <RemoteCardCode v-if="currentTab === 8" />
        </div>
        <div>
          <ElectricWaterWastewater v-if="currentTab === 9" />
        </div>
        <div>
          <RentConsessions v-if="currentTab === 10" />
        </div>
        <div>
          <IntrusionAlarm v-if="currentTab === 11" />
        </div>
        <div>
          <AdditionalProvision v-if="currentTab === 12" />
        </div>
        <div>
          <AnimalAddendum v-if="currentTab === 13" />
        </div>
        <div>
          <RentersInsurance v-if="currentTab === 14" />
        </div>
        <div>
          <EarlyLease v-if="currentTab === 15" />
        </div>
        <div>
          <LeadHazard v-if="currentTab === 16" />
        </div>
        <div>
          <LeaseGuaranty v-if="currentTab === 17" />
        </div>
        <div>
          <SummaryInformation v-if="currentTab === 18" />
        </div>
        <div>
          <Covid19Forms v-if="currentTab === 19" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModalNavigation from "@/mixins/ModalNavigation";
import Loader from "@/components/ui/Loader";
import Icon from "@/components/ui/Icon";
import LeaseTerms from "@/views/lease/LeaseTerms";
import StudentLease from "@/views/lease/StudentLease";
import OtherCharges from "@/views/lease/OtherCharges";
import SpecialProvisions from "@/views/lease/SpecialProvisions";
import Attachments from "@/views/lease/Attachments";
import AnimalAddendum from "@/views/lease/AnimalAddendum";
import LeadHazard from "@/views/lease/LeadHazard";
import EarlyLease from "@/views/lease/EarlyLease";
import AllocationAddenda from "@/views/lease/AllocationAddenda";
import MiscellaneousAddenda from "@/views/lease/MiscellaneousAddenda";
import RemoteCardCode from "@/views/lease/RemoteCardCode";
import ElectricWaterWastewater from "@/views/lease/ElectricWaterWastewater";
import RentConsessions from "@/views/lease/RentConsessions";
import IntrusionAlarm from "@/views/lease/IntrusionAlarm";
import AdditionalProvision from "@/views/lease/AdditionalProvision";
import RentersInsurance from "@/views/lease/RentersInsurance";
import LeaseGuaranty from "@/views/lease/LeaseGuaranty";
import SummaryInformation from "@/views/lease/SummaryInformation";
import Covid19Forms from "@/views/lease/Covid19Forms";

export default {
  mixins: [ModalNavigation],
  components: {
    Loader,
    Icon,
    LeaseTerms,
    StudentLease,
    OtherCharges,
    SpecialProvisions,
    Attachments,
    AnimalAddendum,
    LeadHazard,
    EarlyLease,
    AllocationAddenda,
    MiscellaneousAddenda,
    RemoteCardCode,
    ElectricWaterWastewater,
    RentConsessions,
    IntrusionAlarm,
    AdditionalProvision,
    RentersInsurance,
    LeaseGuaranty,
    SummaryInformation,
    Covid19Forms,
  },
  data() {
    return {
      currentTab: 1,
      isActive: true,
      loading: false,
      canSeeStandardFroms: true,
      canSeeCustomForms: false,
    };
  },
  mounted() {
    this.fetchTimeZones();
  },
  methods: {
    saveData() {},
    fetchTimeZones() {
      this.$authDataProvider
        .getList("timezones")
        .then((content) => {
          this.timezones = content.map(({ code }) => ({
            key: code,
            value: code,
          }));
        })
        .catch((err) => this.notifyError(err));
    },
    selectTab(nextTab) {
      if (this.currentTab !== nextTab) {
        this.isActive = true;
        this.currentTab = nextTab;
      }
    },
    toggleStandardForms() {
      this.canSeeStandardFroms = !this.canSeeStandardFroms;
    },
    toggleCustomForms() {
      this.canSeeCustomForms = !this.canSeeCustomForms;
    },
  },
};
</script>

<style scoped>
.no-margin-x {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.no-margin-r {
  margin-right: 0px !important;
}
input {
  accent-color: var(--highlightColor500) !important;
}
.active {
  background: rgb(231, 237, 247);
  color: var(--highlightColor500) !important;
  box-shadow: 0 0 0 2px rgb(218, 230, 248) inset;
}
.sidenav {
  margin-right: 32px;
  background: rgb(248, 248, 248);
  border: 2px solid rgb(236, 235, 235);
  padding: 0 1.5rem 0 1.5rem;
}
.label {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
}
.lease-options {
  padding: 0 0;
}
.form-tab {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  padding: 0.5rem 0 0.5rem 0;
  margin-bottom: 0.25rem;
  color: black;
}
.form-icon {
  padding: 0 1rem 0 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.form-name {
  width: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.text-title {
  color: black;
  font-size: 0.875rem;
  font-weight: 600;
  font-family: "Inter", sans-serif;
  display: block;
  user-select: none;
  letter-spacing: 0.025rem;
  text-align: justify;
  display: flex;
  align-items: center;
}

.stick {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
button {
  outline: none;
  box-shadow: none;
  margin-bottom: 14px;
}
</style>

